class FieldContent {
  constructor(type, required = false, options) {
    this.type = type;
    this.required = required;
    this.options = options;

    return {
      type: this.type,
      required: this.required,
      error: this.errorMessage(this.type),
      ...this.options,
    };
  }

  errorMessage(type) {
    switch (type) {
      case 'text':
        return `Please fill in this field before continuing`;
      case 'email':
        return `Please provide a valid email address`;
      case 'tel':
        return `Please provide a valid phone number`;
      case 'file':
        return `Please upload a PDF`;
      case 'url':
        return `Please provide a valid URL`;
      default:
        return `Please fill in this field before continuing`;
    }
  }
}

export default FieldContent;
